<script setup lang="ts">
import { BaseIcon } from '@legacy-studio/library';
import { useToggle } from '@vueuse/core';

const [isOpen, toggle] = useToggle();
const { availableLocales, locale } = useI18n();

const items = computed(() => {
	return availableLocales.map(loc => ({
		label: loc,
		icon: `/img/lang/${loc}.png`,
		value: loc,
	}));
});
// TODO: locale type an icons

function change(value: string) {
	locale.value = value;
	toggle(false);
}
</script>

<template>
	<button
		class="relative flex h-10 items-center rounded-lg bg-gray-900 px-3 hover:bg-gray-800 hover:stroke-gray-400 md:h-[45px] xl:px-5"
		@click="toggle()"
	>
		<img
			:src="`/img/lang/${$i18n.locale}.png`"
			class="w-5"
		>

		<span class="ml-2 hidden text-sm font-medium text-white md:inline">
			{{ locale }}
		</span>

		<BaseIcon
			name="base--fill--chevron-down"
			class="ml-2 text-[18px] text-gray-400"
			:class="{
				'rotate-180': isOpen,
			}"
		/>

		<div
			v-if="isOpen"
			class="absolute left-0 right-0 top-[64px] flex w-full flex-col overflow-hidden rounded-lg bg-gray-900 py-1 md:top-[73px]"
			style="
        box-shadow:
          0px 4px 6px -1px rgba(0, 0, 0, 0.1),
          0px 2px 4px -2px rgba(0, 0, 0, 0.05);
      "
			@click.stop
		>
			<button
				v-for="item in items"
				:key="item.value"
				class="flex h-[30px] items-center gap-3 bg-gray-900 px-4 py-2 text-sm font-normal text-gray-300 hover:bg-gray-800 md:h-[37px]"
				@click="change(item.value)"
			>
				<img
					:src="item.icon"
					class="w-[18px] md:w-5"
				>

				<span>
					{{ item.label }}
				</span>
			</button>
		</div>
	</button>
</template>

<script setup lang="ts">
import { AppSuspense, useAppAsyncData } from '@/src/features/app';
import { MarketingFooter, MarketingHeader } from '@/src/features/marketing';

useAppAsyncData();
</script>

<template>
	<MarketingHeader />

	<AppSuspense>
		<slot />
	</AppSuspense>

	<MarketingFooter />
</template>

<script setup lang="ts">
import { BaseLink } from '@legacy-studio/library';
import { useLinks } from '../hooks';
import { UiDarkBBButton, UiLogo } from '~/src/shared/ui';
import { useRegistrationPageLink } from '~/src/shared/lib';

const links = useLinks();
const registrationPageLink = useRegistrationPageLink();
</script>

<template>
	<footer class="border-t border-gray-600 bg-gray-800">
		<div class="mx-auto max-w-[1440px] px-4 py-16 md:px-8 xl:px-20">
			<div
				class="mx-auto mb-8 flex max-w-[480px] flex-col items-center text-center"
			>
				<UiLogo
					size="sm"
					class="mb-6"
				/>

				<p class="mb-8 text-base font-normal text-gray-400">
					Регистрируйтесь и получите $ 10.000 на свой демо-счет для тренировки
					перед реальной торговлей
				</p>

				<BaseLink :to="registrationPageLink">
					<UiDarkBBButton
						button-text="Попробовать бесплатно"
						type="submit"
					/>
				</BaseLink>
			</div>

			<div>
				<p class="mb-4 text-left text-7xl font-semibold text-gray-500">
					18+
				</p>

				<p class="mb-8 text-xs font-normal text-gray-500">
					Транзакции, предлагаемые на этом Веб-сайте, могут выполняться только
					полностью дееспособными совершеннолетними лицами. Операции с
					финансовыми инструментами, предлагаемыми на Веб-сайте, сопряжены со
					значительным риском, и торговля может быть очень рискованной. Если вы
					совершаете Транзакции с финансовыми инструментами, предлагаемыми на
					этом Веб-сайте, вы можете понести значительные убытки или даже
					потерять все на своем Счете. Прежде чем вы решите начать Транзакции с
					финансовыми инструментами, предлагаемыми на Веб-сайте, вы должны
					ознакомиться с Соглашением об оказании услуг и Информацией о раскрытии
					рисков.
				</p>

				<ul class="mb-8 flex flex-wrap gap-x-8 gap-y-4 md:justify-center">
					<li
						v-for="link in links"
						:key="link.title"
						class="text-sm font-medium text-white"
					>
						<BaseLink :to="link.href">
							{{ link.title }}
						</BaseLink>
					</li>
				</ul>

				<p class="text-base font-normal text-gray-400 md:text-center">
					© 2023 BBroker, Inc. All rights reserved.
				</p>
			</div>
		</div>
	</footer>
</template>

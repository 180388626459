<script setup lang="ts">
import { BaseLink, BaseIcon } from '@legacy-studio/library';
import { useToggle } from '@vueuse/core';
import { useLinks } from '../hooks';
import MarketingLocalePicker from './LocalePicker.vue';
import { UiDarkBBButton, UiLogo } from '~/src/shared/ui';
import {
	useIndexPageLink,
	useLoginPageLink,
	useRegistrationPageLink,
} from '~/src/shared/lib';

const [isOpen, toggle] = useToggle();
const links = useLinks();
const indexPageLink = useIndexPageLink();
const loginPageLink = useLoginPageLink();
const registrationPageLink = useRegistrationPageLink();
</script>

<template>
	<header class="sticky top-0 z-40 bg-gray-900">
		<div
			class="mx-auto flex h-[84px] max-w-[1440px] justify-between px-4 pt-1 md:h-[93px] md:pt-0 xl:gap-8 xl:px-20"
		>
			<BaseLink
				:to="indexPageLink"
				class="flex"
			>
				<UiLogo
					with-text
					size="sm"
				/>
			</BaseLink>

			<nav
				v-if="$viewport.match('xl')"
				class="flex items-center"
			>
				<ul class="flex items-center gap-8">
					<li
						v-for="link in links"
						:key="link.href"
					>
						<BaseLink
							:to="link.href"
							class="text-base font-medium text-white"
							v-html="link.title"
						/>
					</li>
				</ul>
			</nav>

			<div class="flex flex-grow items-center justify-end gap-4">
				<MarketingLocalePicker />

				<BaseLink
					v-if="$viewport.match('xl')"
					:to="loginPageLink"
					class="text-sm font-medium text-white"
				>
					Вход
				</BaseLink>

				<BaseLink
					v-if="$viewport.match('xl')"
					:to="registrationPageLink"
				>
					<UiDarkBBButton button-text="Регистрация" />
				</BaseLink>

				<button
					v-if="!$viewport.match('xl')"
					class="text-white"
					@click="toggle()"
				>
					<BaseIcon
						:name="isOpen ? 'base--fill--menu-alt-1' : 'base--fill--menu'"
						class="text-[33px]"
					/>
				</button>
			</div>
		</div>
	</header>

	<div
		v-if="isOpen && !$viewport.match('xl')"
		class="fixed bottom-0 left-0 right-0 top-[84px] z-30 bg-gray-900 md:top-[93px]"
	>
		<nav class="flex">
			<ul class="flex w-full flex-col border-t border-gray-600">
				<li
					v-for="link in links"
					:key="link.href"
					class="w-full"
				>
					<BaseLink
						:to="link.href"
						class="block w-full border-b border-gray-600 px-4 py-3 text-base font-medium text-gray-400 hover:text-white"
					>
						{{ link.title }}
					</BaseLink>
				</li>
			</ul>
		</nav>

		<div
			class="mx-auto mt-8 flex max-w-[375px] flex-col items-center gap-4 px-4"
		>
			<BaseLink
				:to="registrationPageLink"
				class="w-full"
			>
				<UiDarkBBButton
					button-text="Регистрация"
					class="w-full"
				/>
			</BaseLink>

			<BaseLink
				:to="loginPageLink"
				class="text-sm font-medium text-white"
			>
				Вход
			</BaseLink>
		</div>
	</div>
</template>
